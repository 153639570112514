import { AdBreaksParsingType, OpportunityType } from '../../../../consts/adBreaks';
import { OpportunityInfo, PlacementInfo, PlayerAdBreak } from '../../../../shared';

export type Opportunity = {
  opId: string,
  info: OpportunityInfo,
};

export interface HlsPlacementInfo extends PlacementInfo {
  duration: number;
  sequence: number;
}

export type Placement = {
  opId: string,
  info: HlsPlacementInfo,
  startTime: number, 
  opportunityType: OpportunityType,
  oppSecondsDelta?: number,
  oppTimeOffsetMerged?: number,
};

export interface PlacementCollection {
  [key: string]: Placement[];
}

/**
 * Return start time for the placement period.
 * 
 * see:
 * https://wikiprojects.upc.biz/display/PERS/Inserted+Ads#InsertedAds-Placement-HLS-Beginning
 */
export const getPlacementStartTime = (
  opportunity: Opportunity, 
  placements: Placement[]
): number => {
  const placementStartTime = placements.reduce((time, ad) => time + ad.info.duration, 0);

  return opportunity.info.timeOffsetMerged + placementStartTime;
};

/**
 * Creates player ad breaks model from the placement collection.
 */
export const createPlayerAdBreaks = (placementCollection: PlacementCollection, type: AdBreaksParsingType): PlayerAdBreak[] => {
  const placements = Object.values(placementCollection);
  
  return placements.reduce((acc: PlayerAdBreak[], placements) => {
    for (const placement of placements) {
      const playerAdBreak = {
        startTime: placement.startTime,
        endTime: placement.startTime + placement.info.duration,
        adType: type,
        payload: placement.info.reportingMetadata,
        opportunityType: placement.opportunityType,
        oppSecondsDelta: placement.oppSecondsDelta,
        oppTimeOffsetMerged: placement.oppTimeOffsetMerged,
        placementCategory: placement.info.category,
      };

      acc.push(playerAdBreak);
    }
    
    return acc;
  }, []);
};
