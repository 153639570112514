import { isNil } from 'ramda';
import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import DataCue from '../../../../dataCue';
import { HlsAdBreaksParserOptions, OpportunityInfo, ParseOpportunity, ParsePlacement, PlayerAdBreak, PlayerAdBreaksInfo } from '../../../../shared';
import { getMetadataCues } from '../../../hls/video';
import { insertSorted } from '../../../sort';
import {
  DateRangeData, parseDataCueData
} from '../utils';
import {
  createPlayerAdBreaks,
  getPlacementStartTime,
  HlsPlacementInfo,
  Opportunity,
  PlacementCollection
} from './utils';

export interface InsertedHlsAdBreaksParser {
  parseAdBreaks(options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo;
}

export interface InsertedAdBreaksParserDeps {
  readonly parseOpportunity: ParseOpportunity;
  readonly parsePlacement: ParsePlacement;
}

/**
 * Inserted ad-breaks are added into the original asset by video head end components(MDC). 
 * 
 * HLS Inserted ad breaks parser.
 * 
 * see:
 * https://wikiprojects.upc.biz/display/PERS/Inserted+Ads#InsertedAds-Opportunity-HLS
 */
export default class InsertedAdBreaksParserService implements InsertedHlsAdBreaksParser {
  static readonly INSERTED_DATA_TYPE = 'XML';
  static readonly DATA_ENCODING = 'BASE64';
  static readonly PLACEMENT_EVENT = 'PLSTART';
  static readonly OPPORTUNITY_EVENT = 'OPSTART';
  static readonly AD_ATTRIBUTE = 'X-COM-DMDSDP-AVAD';

  protected readonly parseOpportunity: ParseOpportunity;
  protected readonly parsePlacement: ParsePlacement;

  constructor(deps: InsertedAdBreaksParserDeps) {
    this.parseOpportunity = deps.parseOpportunity;
    this.parsePlacement = deps.parsePlacement;
  }

  protected get adBreaksType(): AdBreaksParsingType {
    return AdBreaksParsingType.none;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public parseAdBreaks(_options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo {
    return {
      adBreaks: []
    };
  }

  protected parseInsertedAdBreaks(textTrackList?: TextTrackList): PlayerAdBreak[] {
    if (!textTrackList) {
      return [];
    }

    const metadataTracks = getMetadataCues(textTrackList);

    if (!metadataTracks) {
      return [];
    }

    const dateRangesData = this.parseInsertedAdBreaksDateRanges([...metadataTracks]);
    const opportunityInfo = this.parseOpportunityInfo(dateRangesData);
    const placementInfo = this.parsePlacementInfo(dateRangesData, opportunityInfo);

    return createPlayerAdBreaks(placementInfo, this.adBreaksType);
  }

  protected parseInsertedAdBreaksDateRanges(metadataTracks: TextTrackCue[]): DateRangeData[] {
    return metadataTracks.map((dataCue) => dataCue as DataCue)
      .filter((dataCue) => dataCue.value.key === InsertedAdBreaksParserService.AD_ATTRIBUTE)
      .map((dataCue) => dataCue.value.data)
      .map(parseDataCueData);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected checkOpportunityType(_opportunityInfo: OpportunityInfo): boolean {
    return true;
  }

  protected parseOpportunityInfo(adBreaksData: DateRangeData[]): Opportunity[] {
    return adBreaksData
      .filter((data) => this.validateInsertedDateRange(data, InsertedAdBreaksParserService.OPPORTUNITY_EVENT))
      .reduce((acc: Opportunity[], adData) => {
        const opportunityInfo = this.parseOpportunity(atob(adData.data || ''));

        if (!opportunityInfo || !this.checkOpportunityType(opportunityInfo)) {
          return acc;
        }

        const opportunity = {
          info: opportunityInfo,
          opId: adData.opId || ''
        };

        return [...acc, opportunity];
      }, []);
  }

  protected parsePlacementInfo(adBreaksData: DateRangeData[], opportunities: Opportunity[]): PlacementCollection {
    let placementsData = adBreaksData
      .filter((data) => this.validateInsertedDateRange(data, InsertedAdBreaksParserService.PLACEMENT_EVENT));

    return opportunities
      .reduce((acc: PlacementCollection, opp): PlacementCollection => {
        let placementOpId = opp.opId;

        placementsData
          .filter((data) => data.opId == placementOpId)
          .map((adData) => this.parsePlacement(atob(adData.data || '')) as HlsPlacementInfo)
          .sort((a, b) => a.sequence - b.sequence)
          .forEach((placementInfo) => {
            if (!placementInfo) {
              return;
            }

            if (isNil(placementInfo.sequence) || isNil(placementInfo.duration)) {
              return;
            }

            acc[placementOpId] = acc[placementOpId] || [];

            const placements = acc[placementOpId] || [];

            const placementStart = getPlacementStartTime(opp, placements);

            const placement = {
              info: placementInfo,
              startTime: placementStart,
              opId: placementOpId,
              opportunityType: opp.info.type,
              oppSecondsDelta: opp.info.secondsDelta,
              oppTimeOffsetMerged: opp.info.timeOffsetMerged,
              placementCategory: placementInfo.category,
            };

            acc[placementOpId] = insertSorted(
              placements, placement, (a, b) => a.info.sequence - b.info.sequence
            );
          });

        return acc;
      }, {});
  }

  protected validateInsertedDateRange(dateRange: DateRangeData, event: string): boolean {
    if (!dateRange.data || !dateRange.opId) {
      return false;
    }

    return (
      dateRange.event === event
      && dateRange.dataType === InsertedAdBreaksParserService.INSERTED_DATA_TYPE
      && dateRange.dataEncoding === InsertedAdBreaksParserService.DATA_ENCODING
    );
  }
}